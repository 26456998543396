import Vue from 'vue';
import VueRouter, { Location, Route, RouteConfig } from 'vue-router';

const home = () => import('./components/home').then(({ Home }) => Home);
const about = () => import('./components/about').then(({ About }) => About);
const team = () => import('./components/team').then(({ Team }) => Team);
const projects = () => import('./components/projects').then(({ Projects }) => Projects);
const projects_jc3mp = () => import('./components/projects/jc3mp').then(({ JC3MP }) => JC3MP);
const projects_framework = () => import('./components/projects/framework').then(({ Framework }) => Framework);
const projects_world = () => import('./components/projects/world').then(({ World }) => World);
const contact = () => import('./components/contact').then(({ Contact }) => Contact);
const imprint = () => import('./components/imprint').then(({ Imprint }) => Imprint);
const privacy = () => import('./components/privacy').then(({ Privacy }) => Privacy);
const notfound = () => import('./components/notfound').then(({ PageNotFound }) => PageNotFound);

Vue.use(VueRouter);

export const createRoutes: () => RouteConfig[] = () => [
    {
        path: '/',
        component: home
    },
    {
        path: '/about',
        component: about
    },
    {
        path: '/projects',
        component: projects
    },
    {
        path: '/contact',
        component: contact
    },
    {
        path: '/imprint',
        component: imprint
    },
    {
        path: '/privacy',
        component: privacy
    },
    {
        path: '*',
        component: notfound
    }
];
export const createRouter = () =>
    new VueRouter({
        mode: 'history',
        routes: createRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        }
    });
