import { Component, Vue } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import fontawesome from '@fortawesome/fontawesome';
import faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import faFacebookF from '@fortawesome/fontawesome-free-brands/faFacebookF';
import faYoutube from '@fortawesome/fontawesome-free-brands/faYoutube';

fontawesome.library.add(faTwitter, faInstagram, faFacebookF, faYoutube);

import './footer.scss';

@Component({
    template: require('./footer.html'),
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export class Footer extends Vue {
    open_external(link: string): void {
        window.open(link, '_blank');
    }
}
