






import {Component, Vue, Prop} from 'vue-property-decorator';
@Component({
    name: 'sub-page-header',
})
export default class SubPageHeader extends Vue {
    @Prop(String)
    title: string;
}
