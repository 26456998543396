





import {Component, Vue, Prop} from 'vue-property-decorator';
@Component({
    name: 'sub-page-content',
})
export default class SubPageContent extends Vue {
}
