var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data.links && !_vm.data.external
    ? _c(
        "router-link",
        {
          staticClass: "navbar-item dropdown",
          attrs: { tag: "a", to: _vm.data.path },
          on: { touchstart: _vm.touchstart },
          nativeOn: {
            click: function($event) {
              return _vm.touchstart($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "dropdown-text" },
            [
              _vm._v(_vm._s(_vm.data.name)),
              _c("font-awesome-icon", {
                staticClass: "dropdown-icon",
                attrs: { icon: "caret-down" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dropdown-content" },
            _vm._l(_vm.data.links, function(link) {
              return _c("navbar-item", {
                key: link.path,
                attrs: { data: link }
              })
            })
          )
        ]
      )
    : _vm.data.links && _vm.data.external
      ? _c(
          "a",
          {
            staticClass: "navbar-item dropdown",
            attrs: { href: _vm.data.link },
            on: { touchstart: _vm.touchstart },
            nativeOn: {
              click: function($event) {
                return _vm.touchstart($event)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "dropdown-text" },
              [
                _vm._v(_vm._s(_vm.data.name) + " "),
                _c("font-awesome-icon", {
                  staticClass: "dropdown-icon",
                  attrs: { icon: "caret-down" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-content" },
              _vm._l(_vm.data.links, function(link) {
                return _c("navbar-item", {
                  key: link.path,
                  attrs: { data: link }
                })
              })
            )
          ]
        )
      : !_vm.data.external
        ? _c(
            "router-link",
            {
              staticClass: "navbar-item",
              attrs: { tag: "a", to: _vm.data.path }
            },
            [_c("div", [_vm._v(_vm._s(_vm.data.name))])]
          )
        : _c(
            "a",
            {
              staticClass: "navbar-item",
              attrs: {
                href: _vm.data.path,
                target: "_blank",
                rel: "noopener noreferrer"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                }
              }
            },
            [_c("div", [_vm._v(_vm._s(_vm.data.name))])]
          )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }