import Vue from 'vue';
import App from './app.vue';
import VueLazyload from 'vue-lazyload';
import { createRouter } from './router';
import './style/main.scss';
import './style/font.scss';

Vue.use(VueLazyload);

// tslint:disable-next-line:no-unused-expression
const root = new Vue({
    router: createRouter(),
    render: h => h(App)
});

(window as any).snapSaveState = () => {
    document.querySelector('#app').setAttribute('data-server-rendered', 'true');
};

if (document.readyState === 'complete' 
    // || document.readyState === 'loaded' 
    || document.readyState === 'interactive') {
    let node = document.querySelector('#app');
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
    root.$mount('#app');
} else {
    window.addEventListener('DOMContentLoaded', function () {
        let node = document.querySelector('#app');
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }
        root.$mount('#app');
    });
}