




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { Link } from './link';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

library.add(faCaretDown)

@Component({
    name: 'navbar-item',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    }
})
export default class NavbarItem extends Vue {
    @Prop(Link)
    data: Link;

    touchstart(e) {
        console.log('Touch');
        e.preventDefault();
    }
}
