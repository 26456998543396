







import { Component, Vue } from 'vue-property-decorator';
import {Navbar} from './components/navbar';
import {Footer} from './components/footer';
import SubPageHeader from './components/subpageheader.vue';
import SubPageContent from './components/subpagecontent.vue';
Vue.component('sub-page-header', SubPageHeader);
Vue.component('sub-page-content', SubPageContent);
@Component({
    components: {
        'navbar': Navbar,
        'foot': Footer,
    }
})
export default class App extends Vue
{
    name: string = 'app';
}
