import { Component, Vue } from 'vue-property-decorator';
import { Link } from './link';
import NavbarItem from './navbar-item.vue';
import './navbar.scss';

@Component({
    template: require('./navbar.html'),
    components: {
        NavbarItem
    }
})
export class Navbar extends Vue {
    links: Link[] = [
        new Link('Projects', '/projects', false, /*[
             new Link('Framework', '/projects/framework'),
             new Link('World', '/projects/world'),
             new Link('JC3MP', '/projects/jc3mp')
        ]*/),
        new Link('About', '/about', false, /*[
            new Link('Team', '/about/team'),
        ]*/),
        new Link('Community', 'https://discord.gg/nanos', true),
        new Link('Contact', '/contact'),
    ];

    navbar_visible: boolean = false;
    color_switch: boolean = false;

    toggleVisible() {
        this.navbar_visible = !this.navbar_visible;
    }

    handleScroll(): void {
        const color_switch_element = document.querySelector('.navbar-color-switch') as HTMLElement;
        if (color_switch_element) {
            this.color_switch = window.scrollY + 80 >= color_switch_element.offsetTop;
        } else {
            const color_switch_element_after = document.querySelector('.navbar-color-switch-after')  as HTMLElement;
            if (color_switch_element_after) {
                this.color_switch = window.scrollY + 80 >= color_switch_element_after.offsetTop + color_switch_element_after.offsetHeight;
            } else {
                this.color_switch = false;
            }
        }
    }

    beforeMount(): void {
        window.addEventListener('scroll', this.handleScroll);
    }

    beforeDestroy(): void {
        window.removeEventListener('scroll', this.handleScroll);
    }

    get logoImage() {
        return require('assets/images/logo.svg');
    }
}
