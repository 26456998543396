export class Link {
    name: string;
    path: string;
    active: boolean = true;
    external: boolean = false;
    links: Link[] = null;

    constructor(name: string, path: string, external?: boolean, links?: Link[]) {
        this.name = name;
        this.path = path;
        this.external = external;
        this.links = links || null;
    }
}
